<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="my-5">
        <h3>{{ $t('Common.invitation_title') }}</h3>
      </v-col>
      <v-col cols="12">
        <span class="fw-bold mb-3 d-block">{{ $t('Common.invitation_sub') }}</span>
      </v-col>
      <v-col cols="4">
        <span>or upload a csv file with "emails" header"</span>
      </v-col>
      <v-col cols="3">
        <file-upload
          extensions="csv"
          accept="application/csv"
          name="emails"
          class="pa-2 v-btn theme--light grey lighten-1"
          :drop="true"
          v-model="files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          ref="upload"
          :disabled="true"
        >Upload</file-upload>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5">
        <v-alert v-if="!validEmail" type="error" outlined :value="true">Email(s) is Invalid</v-alert>
        <v-text-field
          :disabled="sent"
          name="email"
          label="Email"
          id="email"
          v-model="email"
          @input="validEmail = true"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5">
        <v-textarea
          name="message"
          filled
          label="Optional Message"
          auto-grow
          v-model="message"
          placeholder="Add your personal notes. For example,  Hello, I joined TakeIn and invite you to do the same"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5">
        <v-btn
          id="send_btn"
          rounded
          color="info"
          type="submit"
          :disabled="sent"
          @click.prevent="invite"
          :loading="btnLoad"
        >
          Invite
          <v-icon right>mail</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- error message display -->
    <v-snackbar :timeout="6000" color="red" :vertical="false" v-model="snackbar">
      {{ errorMsg }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row v-if="sent" row wrap>
      <v-col class="pt-5" cols="12" sm6>
        <h3>
          Thank you! Invite
          <a @click="more">more</a>
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import FileUpload from "vue-upload-component";

export default {
  components: {
    FileUpload
  },
  data() {
    return {
      validEmail: true,
      email: null,
      message: null,
      sent: false,
      errorMsg: null,
      snackbar: false,
      btnLoad: false,
      files: []
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    more() {
      this.email = null;
      this.message = null;
      this.sent = false;
    },
    // checks if email in text filled is legitimate
    inputIsValid(emails) {
      let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      for (let email of emails) {
        if (!regex.test(email.toLowerCase().trim())) {
          this.validEmail = false;
          return false;
        }
      }
      return true;
    },
    invite() {
      let emails = this.email.split(",");
      if (this.inputIsValid(emails)) {
        emails.forEach(email => {
          this.btnLoad = true;
          email = email.toLowerCase().trim();
          if (email === this.user.email) {
            email.forE;
            this.ownEmail = true;
            this.$store
              .dispatch("setError", {
                message: "Can not invite yourself! "
              })
              .then((this.btnLoad = false));
            return;
          }
          // first checks if there is an existing invite that has not been seen yet
          this.$store
            .dispatch("inviteExists", { sender: this.user.id, to: email })
            .then(exists => {
              if (exists) {
                this.btnLoad = false;
                this.setError("invite_exist");
                return;
              } else {
                console.log("Invitation sent to ", email, this.user.email);
                this.sent = true;
                this.btnLoad = false;
                this.$store.dispatch("invite", {
                  sender: this.user.id,
                  to: email,
                  message: this.message
                });
              }
            });
        });
      }
    },
    // sets error message for snackbar
    setError(msg) {
      switch (msg) {
        case "own_email":
          this.errorMsg = this.$t("Common.own_email_error");
          break;
        case "invite_exist":
          this.errorMsg = this.$t("Common.invite_exist_error");
          break;
      }
      this.snackbar = true;
    },
    inputFile(newFile) {
      console.log(newFile.file);
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(csv)$/i.test(newFile.name)) {
          alert("Your choice is not a CSV file!");
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    }
  }
};
</script>
